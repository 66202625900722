.subadmincontainer{
    margin: 1% 20% 10% 20%;
    background-color: #9fa8c5;
  
    
    }

    .formheading{
        display: flex;
        justify-content: space-between;
        padding:4% 6%;
        border-bottom: 10px solid transparent;
        background-color: #2e3c69;
        color:white
    }
    
    .subadminlabel {
        color:black;
        font-size: 20px;
        font-weight: 500;
        margin-right: 10px;
    }
    
    .subadmininput {
        width:100%;
        border:1px solid #2e3c69;
       
        padding: 10px;
        color:#2e3c69;
    
    }
    .table .thead-light th {
      color: #495057;
      background-color: #e9ecef;
      border-color: #dee2e6;
  }
    input:focus{
        outline: none !important;
    }
    
    .subadmininput1 {
        width:20%;
        border:1px solid #2e3c69;
        border-radius: 20px;
        padding: 10px;
        color:#2e3c69;
    margin:0% 5% 5% 5%;
    color:white;
    border:1px solid white;
    }
    
    .modal1{
        margin:0% 20% 15% 20%;
       height:100%
    }

    .accessodulesinput{
        font-size: 20px;
    }
    

  .subadmmineye{
    font-size: 30px;
    margin:0px 30px;
    cursor: pointer;
    color:#2e3c69;
  }

  .subadmincheck{
    color:green;
    font-size: large;
    font-weight: 900;
  }

  
  .subadminclose{
    color:red;
    font-size: large;
    font-weight: 900;
  }

  .modal-content {
    background-color: transparent;
    border-color:transparent;
  }
  /* .modal.show .modal-dialog {
    transform: none;
    background-color: white !important;
} */