

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Create two unequal columns that floats next to each other */
/* Left column */
.leftcolumn {
  float: left;
  width: 75%;
}

/* Right column */
.rightcolumn {
  float: left;
  width: 25%;
  padding-left: 20px;
}

/* Fake image */
.fakeimg {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

/* Add a card effect for articles */
.card {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
  padding: 0px 10px;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0px 40px !important;
  color:white !important
}
@media only screen and (max-width: 600px) {
  .d-block {
    height: 150px !important;
    margin: 25px 0px !important;
  }
  .card {
    width: auto !important;
    margin: auto !important;
    height: auto !important;
  }
  .hh {
    font-size: 30px !important;
  }
  /* img {
    vertical-align: middle;
    width: auto !important;
  } */
  .man {
    width: 60% !important;
  }
  /* .btn {
    width: 43% !important;
  } */
  .tt {
    font-size: 15px !important;
  }
  /* .section-title {
    margin-top: -226px !important ;
  } */
  .rounded {
    width: 250px !important;
  }
  .carousel-caption {
    margin: -145px 0px;
  }
  .home-tow-testimonial-item1 {
    height: 0px !important;
    margin: -253px 0px !important;
  }
  .text-white.text-center {
    font-size: 20px !important;
  }
  .btn {
    font-size: 10px !important;
    padding: 5px 2px !important;
  }
  .btn1 {
    font-size: 10px !important;
    padding: 5px 2px !important;
  }
  .container {
    display: block !important;
  }
  .ggc {
    margin: 90px -115px !important;
  }
  .kkkk {
    height: 200px !important;
  }
  .rr {
    margin: auto !important;
  }
  .card.d {
    /* width: 15rem !important; */
  }
  /* .card-img-top{
    width: 14rem !important;
  } */
  .img-fluid {
    height: 230px !important;
  }
  .pp {
    margin: auto !important;
  }
  .text-white.mb-0.align-items-center {
    font-size: 50px !important;
    margin-left: -40px;
  }
  .yt {
    margin: auto !important;
  }
  .ew {
    margin: auto !important;
  }
}

/* <mediaQuary  Abo>  */

@media only screen and (max-width: 600px) {
  .kkc {
    padding: 0px !important;
  }
  .card-body {
    height: auto !important;
  }
  .uu {
    height: auto !important;
    margin-top: auto !important;
    width: auto !important;
  }
  .element.style {
    padding-top: auto !important;
    font-size: 23px !important;
  }
  .container-1 {
    padding: auto !important;
  }
  .card {
    height: auto !important;
  }
  .df {
    margin: auto !important;
  }
  .card-img {
    height: auto !important;
  }
  .log {
    margin: auto !important;
    width: auto !important;
    /* padding: 0% !important; */
  }
  .re {
    height: 290px !important;
  }
  .cont {
    width: 100px !important;
  }
  .ck {
    margin: -20px 0px 0px -134px !important;
  }
  .form-label {
    /* margin-left: -186px !important; */
  }
  /* .cck {
    margin: -23px 0px 0px -85px !important;
  } */
  .ct {
    padding: 0.5px !important;
  }
  .col-md-3.add {
    height: 100% !important;
    width: 97%;
  }
}