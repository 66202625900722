table {
  font-family: arial, sans-serif;
  /* border-collapse: collapse; */
  width: 100%;
}

td, th {
  /* border: 1px solid skyblue; */
  text-align: left;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
th{
  color: black;
}
/* a{
  text-decoration: none;
  color: rgb(58, 53, 53);
} */
/* li{
  font-size: 18px;
  margin: 10px 20px;
  text-decoration: none;
} */
.btn.btn-outline-info:hover{
background-color: rgb(5, 38, 78);
color: white !important;
}
/* .btn :hover{
  background-color:  rgb(45, 90, 145);
} */


@media only screen and (max-width: 600px) {
 table{
    background-color: lightblue;
    font-size: 7px !important;
  }
  .btn.btn-outline-primary{
    padding: 0px 23px 0px 0px !important;
  }
  .btn.btn-danger{
    padding: 0px 40px 0px 0px !important;
  }
  .btn.btn-success{
    padding: 0px 24px 0px 0px !important;
  }
  .col-md-3.fixed{
    height: 100% !important;
    position: relative !important;
  }
  .text-white {
    margin: 1px !important;
  }
  /* .hoverZoomLink{
   height: 30px !important;
   width: 110% !important;
  } */
  img, svg {
    vertical-align: middle;
    /* width: 20px !important; */
}
.col-md-3.add {
  height: 100% !important;

}
.container.img{
  width: 30% !important;
  display: contents !important;
}
.btn.btn-outline-info{
width: 60px !important;

}
.adim{
  margin-bottom: -69px;
    margin-left: 163px;
}
}

.form-group .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  width: 100%;
}
.radio-fileds-iocn{
  display: flex;

}
/* .radio-field label{
  padding-left:8px;
  font-size: 17px;
} */
.radio-field{
  margin:0px 15px
}
.form-group.joker-0 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  width: 50%;
}
.mult-additional .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width:100%
}
.form-group .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: 'Yantramanav', sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.tex_heo h4{
  font-size: 21px;
  font-weight: 600;
  font-family: 'Yantramanav', sans-serif;
}
.form-group .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  height: 51px;
}
.form-group .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  height: 51px;
}
.radio-fileds-iocn .css-ahj2mt-MuiTypography-root {
  font-weight: 400;
  font-size: 16px;
  font-family: 'Yantramanav', sans-serif;
}
.salar_hero .css-ahj2mt-MuiTypography-root {
  font-weight: 400;
  font-size: 16px;
  font-family: 'Yantramanav', sans-serif;
}
.min_max .form-group .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  height: 51px;
}
.candidate-details h4{
  font-size: 21px;
  font-weight: 600;
  font-family: 'Yantramanav', sans-serif;
}
.hero_hj .css-ahj2mt-MuiTypography-root {
  font-weight: 400;
  font-size: 16px;
  font-family: 'Yantramanav', sans-serif;
}
.loginform h1{
  font-size: 30px;
  font-weight: 600;
  padding-top: 20px;
}
/* df8ihjdv */
.css-p0rm37{
  font-family: 'Yantramanav', sans-serif !important; 
 font-weight: 600 !important;
 font-size: 16px !important;
} 
.radio-fileds-iocn .css-9l3uo3{
  font-weight: 400 !important;
    font-size: 16px !important;
    font-family: 'Yantramanav', sans-serif !important;
}
.css-1v4ccyo{
height:51px !important;
}
.css-1v4ccyo {
  height:51px !important;
}
.css-i44wyl{
  width:100% !important;
}
.css-fvipm8{
  height:51px !important
}