.login-bg-txt .login-icon1 {
  align-items: center;
  background-color: #24496a;
  border-radius: 50%;
  display: flex;
  height: 70px;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 70px;
}
.login-bg-txt strong {
  color: #24496a;
  display: block;
  font-size: 20px;
  font-weight: 600;
}
.login-bg-txt {
  color: #666;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  margin: 90px;
  text-align: center;
  vertical-align: top;
  width: 72px;
}
.login-bg-txt .login-icon2, .login-bg-txt .login-icon3 {
  align-items: center;
  background-color: #24496a;
  border-radius: 50%;
  display: flex;
  height: 70px;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 70px;
}
.login-bg-txt .login-icon2, .login-bg-txt .login-icon3 {
  align-items: center;
  background-color: #24496a;
  border-radius: 50%;
  display: flex;
  height: 70px;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 70px;
}
.span{
  text-decoration: underline blue;
}
.h4{
  font-family: 'Courier New', Courier, monospace;
}
.card-title{
  font-size: 12px;
}
.MDBCardText,h4{
  margin-left: 7px;
}