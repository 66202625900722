@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap');

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  font-family: 'Yantramanav', sans-serif;
}
.nav-link{
  font-size: 20px !important;
  font-weight: 600 !important;
 
}
.form-check-inline {
  display: inline-block !important;
  margin-right: 5rem !important;
}
.mb-20 {
 
  font-weight: 700 !important;
  font-size: 16px !important;
}
.card-briefcase{
  font-size: 13px;
}
.btn-secondary {
  background-color: rgb(43, 183, 146) !important;
  border-color: rgb(43, 183, 146)!important;
  color:white !important
}


a {
  text-decoration: none !important;
  color: #164890 !important
}

.searchWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 24%);
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
}

.searchWrap input {
  outline-width: 0px;
  padding: 10px;
  border: none
}

.topBarRight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.btnLoginRegister {
  display: flex;
  align-items: center;
}

.btnLoginRegister .btn {
  padding: 6px 20px;
  border-radius: 4px;
  margin: 0px 10px;
  font-size: 18px;
  font-weight: 600;
}

.banner-hero {
  padding: 0 65px 0 15px;
  position: relative;
  max-width: 1770px;
  margin: 0 auto;
}

.banner-hero .banner-inner {
  z-index: 5;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.banner-hero .block-banner {
  position: relative;
  z-index: 12;
  padding: 20px 10px 20px 25px;
}

.banner-hero .heading-banner {

  line-height: 60px;
  font-weight: 900;
  font-size: 48px;
  line-height: 48px;
  color: #05264e;
}

.banner-hero .color-brand-2 {
  color: #24496a;
  position: relative;
}

.banner-hero .banner-description {
  position: relative;
  font-size: 18px;
  color: #4f5e64;
}

.banner-hero .banner-description {
  position: relative;
  font-size: 18px;
  color: #4f5e64;
  font-weight: 600;
}

.banner-hero .banner-inner .banner-imgs {
  padding: 65px 0 0;
  position: relative;
  height: 100%;
  min-height: 540px;
}

.banner-hero .banner-inner .banner-imgs .block-1 {
  position: absolute;
  
  left: 114px;
}
.shape-1 button{
  
    padding: 8px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: aqua;
    background-color: black;

}

.shape-1 {
  animation: hero-thumb-animation 2s linear infinite alternate;
}

.banner-hero .banner-inner .banner-imgs .block-3 {
  position: absolute;
  top: 21%;
  right: 220px;
}

.shape-3 {
  animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}

.login-bg-txt {
  width: 123px;
  margin: 10% 0 0 5.5%;
  display: inline-block;
  color: #666;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  vertical-align: top;
}

.login-bg-txt .login-icon1 {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: #24496a;
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.login-bg-txt strong {
  font-size: 30px;
  color: #24496a;
  display: block;
  font-weight: 600;
}

.login-bg-txt .login-icon2 {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: #24496a;
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.login-bg-txt .login-icon3 {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: #24496a;
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.login-signup-box {
  -webkit-box-shadow: 0px 0px 21px 3px #ededed;
  -moz-box-shadow: 0px 0px 21px 3px #ededed;
  box-shadow: 0px 0px 21px 3px #ededed;
  /* position: absolute; */
  /* right: 10%;
  top: 131px; */
  width: 400px;
  /* height: 398px */
}
.add-login{
  display: flex;
  justify-content: center;
  padding: 40px;
}
.register {
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  padding: 20px;
}

.register .form-group {
  position: relative;
}

.form-group {
  margin-bottom: 20px;
}

.register .form-group .form-control {
  border-radius: 0;
  box-shadow: none;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #ccc;
  font-size: 22px;
  color: black;
}

.register .submit {
  background-color: #24496a;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  display: block;
  width: 100%;
  line-height: 30px;
  margin: 20px 0 10px 0;
}
input,
input::placeholder {
    font: 18px/3 sans-serif;
}
input[type="text"]
{
    font-size:20px;
}
.register .modal-title {
  font-size: 22px;
  color: #24496a;
  display: block;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}

.register .form-check-label {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  font-weight: 300;
}

/* .mt-80 {
  margin-top: 33px;
  margin-bottom: 30px;
} */

.mb-10 {
  margin-bottom: 10px;
  font-weight: 700
}

.font-lg {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #66789c;
}

.box-swiper .swiper-container .item-logo {
  border: 1px solid rgba(6, 18, 36, .1);
  padding: 22px 18px;
  display: flex;
  width: 100%;
  text-align: left;
  border-radius: 12px;
  margin-bottom: 15px;
  background-color: #fff;
  flex-wrap: wrap;
}

.box-swiper .swiper-container .item-logo .image-left {
  min-width: 50px;
  padding-right: 10px;
}

.box-swiper .swiper-container .item-logo .text-info-right h4 {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 3px;
}

.font-xs {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.item-logo {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.item-logo {
  border: 1px solid #164890;
  padding: 14px 13px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
  background-color: #164893;
  color: white;

}

.mt-50 {
  margin-top: 30px;
}

.box-we-hiring {
  border: 1px solid #e0e6f7;
  padding: 26px 50px 30px 50px;
  background-color: darkseagreen;
  position: relative;
  display: flex;
  box-shadow: 0 10px 20px -5px rgb(10 42 105 / 6%);
  border-radius: 4px;
  overflow: hidden;
  gap:10px
  /* justify-content: space-between; */
}

.mb-30 {
  margin-top: 50px;
  margin-bottom: 30px;
}

.box-we-hiring .text-we-are {
  font-size: 16px;
  line-height: 20px;
  color: white;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: block;
}

.box-we-hiring .text-hiring {
  font-size: 49px;
  line-height: 51px;
  color: white;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.box-we-hiring .text-2 {
  font-size: 22px;
  line-height: 23px;
  color: #66789c;
  font-weight: 600;
  padding: 29px 20px 0;
}

.color-brand-3 {
  color: white !important;
}

.text-3 .btn-apply {
  background-color: white;
  padding: 8px 26px;
  font-size: 20px;
  width:150px;
  font-weight: 600;
  border-radius: 4px;
  color: #164893;
  /* font-size: 20px; */

}

.jobCard_jobCard_features__wJid6 {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 10px;
  font-size: 1rem;
}

.wrap-type .name {
  font-size: 20px;
}

.jobCard_jobCard_lists__fdnsc {
  display: flex;
  justify-content: space-between;
}

.jobCard_jobCard_jobDetail__jD82J {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  font-size: 1.2rem;
  margin: 12px 0 0;
  padding: 0;
  list-style-type: none;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.homeSlider .jobCard_ctas {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.pr-0 {
  padding-right: 0 !important;
}

.btn-link {
  border: 0;
  color: #24496a !important;
  background-color: transparent;
  /* font-size: 1.4rem; */
  font-weight: 700;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none !important;
}

.wrap-type {
  background-color: #fff;
  border: 1px solid #ececec;
  width: 100%;
  padding: 1.5rem;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  position: relative;
}

.contact-place-90 {
  display: flex;
  justify-content: space-between;
  list-style: none;
  /* padding: 30px; */
}

.card-grid-2 {
  border-radius: 8px;
  border: 1px solid #e0e6f7;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
  background: #f8faff;
}

.card-grid-2 .card-grid-2-image-left {
  padding: 2px 11px 15px;
    display: flex;
    position: relative;
    justify-content: space-between;
}

.card-grid-2-image-left .right-info .name-job {
  font-size: 18px;
  line-height: 26px;
  color: #05264e;
  font-weight: 700;
  display: block;
}

.card-grid-2 .card-block-info {
  display: inline-block;
  width: 100%;
  padding: 5px 10px 20px;
}

.card-time {
  font-size: 12px;
  color: #a0abb8;
  display: inline-block;
  padding: 0 15px;
}

.mt-30 .btn-grey-small {
  background-color: #0a2a69;
  font-size: 12px;
  padding: 7px 10px;
  border-radius: 5px;
  color: white !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.card-block-info .card-text-price {
  color: #24496a;
  font-family: Plus Jakarta Sans, sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.text-muted {
  color: #6c757d !important;
}

.text-end .btn-apply-now {
  background-color: #0a2a69;
  color: white;
  padding: 8px 10px;
    min-width: 95px;
    border-radius: 37px;
    font-size: 14px;
    text-transform: capitalize;
    margin-left: -8px;
    font-weight: 600;
}

.nav-tabs-00 {
  box-shadow: 0 2px 4px -5px rgb(43 183 146) !important;
  background-color: rgb(43, 183, 146) !important;
  color: white;
  align-items: center;
  padding: 13px 4px;
  width: auto;
  border: 1px solid #e0e6f7;
  border-radius: 8px;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  margin: 0 5px 10px;
  text-transform: capitalize;
}

.card-grid-2:hover {
  border-color: #b4c0e0;
  background-color: #fff;
}

.hover-up:hover {
  transform: translateY(-3px);
}

.hover-up,
.hover-up:hover {
  transition: all .25s cubic-bezier(.02, .01, .47, 1);
}

.overflow-visible {
  overflow: visible !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.box-image-job {
  position: relative;
  text-align: center;
}

.box-image-job .img-job-1 {
  position: absolute;
  top: -70px;
  left: -120px;
}

.box-image-job .img-job-2 {
  position: absolute;
  bottom: -170px;
  right: -80px;
}

.box-image-job figure {
  display: block;
}

.box-image-job figure img {
  border-radius: 32px;

}

.content-job-inner {
  padding-left: 60px;
  padding: 40px;
}

.text-52 {
  font-size: 52px;
  line-height: 66px;
  font-weight: 800;
  color: #05264e;
}

.color-brand-2 {
  color: #24496a;
}

.text-32 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 800;
}

.color-text-mutted {
  color: #a0abb8;
}

.mt-40 {
  margin-top: 40px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.mt-40 {
  margin-top: 20px !important;
}

.wow .btn.btn-default {
  color: #fff !important;
  line-height: 26px;
  padding: 10px 25px;
  background-color: #2bb792;
  font-size: 17px;
}

.wow .btn-link {
  font-family: Plus Jakarta Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #05264e;
  text-decoration: underline;
}

.mb-50 {
  margin-bottom:10px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.job-000 {
  font-size: 50px;
  font-weight: 800;
}

.bg-img-about:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  /* background-image:"url('/Images/bg-black-trans.c550ad77.png)')" ; */

}

.bg-img-about h2 {
  color: white
}

.bg-img-about p {
  color: #fff;
}

.font-lg {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

.mt-40 {
  margin-top: 40px !important;
}

.breadcrumbs {
  background-color: #fff;
  border-radius: 6px;
  display: inline-block;
  padding: 7px 15px;
}

.mb-10 {
  margin-bottom: 10px !important;
  font-size: 40px;
  font-weight: 800;
}

.mt-40 {
  margin-top: 40px !important;
}

.breadcrumbs {
  background-color: #fff;
  border-radius: 6px;
  display: inline-block;
  padding: 0px 15px;
}

/* .breadcrumbs li a {
  font-size: 14px;
  color: #66789c;
  text-decoration: none;
  line-height: 14px;
  display: block;
} */
.breadcrumb-item {
  padding-top: 11px !important;
}

.f-18 {
  font-size: 18px;
}

.color-text-mutted {
  color: black;
}

.w-lg-50 {
  width: 50%;
}

.color-text-paragraph {
  color: #4f5e64;
}

.font-sm {
  font-weight: 700;
    font-size: 16px !important;
    line-height: 22px !important;
}

.mt-70 {
  margin-top: 26px !important;
}

.mt-15 {
  margin-top: unset !important;
  font-size: 28px;
  line-height: 35px;
  color: #05264e;
  font-weight: 700;
}

.font-md {
  font-size: 16px !important;
  line-height: 24px !important;
}

.card-grid-4 {
  background-color: #fff;
  padding: 40px 30px 35px;
  border-radius: 10px;
  border: thin solid #e0e6f7;
  margin-bottom: 30px;
}

.card-grid-4 .image-top-feature figure img {
  height: 126px;
  border-radius: 50%;
  width: 126px;
}

.card-grid-4 .card-grid-4-info {
  display: inline-block;
  width: 100%;
  padding: 10px 0 0;
}

.card-grid-3 {
  border-radius: 16px;
  border: 1px solid #e0e6f7;
  overflow: hidden;
  margin-bottom: 0;
  background: #fff;
  margin-top: 10px
}

.card-grid-3 .card-grid-3-image {
  position: relative;
  padding: 10px;
}

.card-grid-3 .card-block-info {
  display: inline-block;
  width: 100%;
  padding: 0 18px 18px;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.tags.mb-15 .btn-tag {
  padding: 3px 10px;
  background: #e0e6f7;
  color: #3c65f5;
}

.card-grid-3 .card-block-info .card-2-bottom .img-rounded {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin: 6px 10px 0 0;
  vertical-align: middle;
  display: inline-block;
}

.card-grid-3 .card-block-info .card-2-bottom .info-right-img {
  line-height: 22px;
}

.card-grid-3 .card-block-info .card-2-bottom .info-right-img .font-sm {
  line-height: 18px !important;
}

.op-70 {
  opacity: .7;
}

.color-text-paragraph-2 {
  color: #66789c;
}

.social-share-link {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 0 2px;
  border: 1px solid lightgray;
  border-radius: 50%;
  line-height: 35px;
  font-size: 20px;
}

.banner-hero.banner-single {
  padding: 40px 20px 60px;
  background: #f2f6fd;
  border-radius: 16px;
  position: relative;
}

.banner-single .block-banner {
  padding: 0;
  max-width: 850px;
  margin: auto;
}

.banner-hero .block-banner .form-find {
  background: #fff;
  box-shadow: 0 18px 40px rgb(25 15 9 / 10%);
  border-radius: 8px;
  display: inline-block;
  width: 100%;
  padding: 10px;
}

.banner-hero .block-banner .form-find .box-industry {
  width: 100%;
  max-width: 180px;
  padding-top: 5px;
}

.banner-hero .block-banner .form-find form {
  display: flex;
  width: 100%;
}

.banner-hero .block-banner .form-find .form-input {
  border: 0 solid rgba(26, 15, 9, .1);
  border-radius: 0;
  padding: 10px 20px 10px 34px;
  display: inline-block;
  width: 100%;
}

.mr-10 {
  margin-right: 10px !important;
}

.banner-hero .block-banner .form-find .form-input {
  border: 0 solid;
  border-radius: 0;
  padding: 10px 20px 10px 34px;
  display: inline-block;
  width: 100%;
  outline-width: 0px;
  text-transform: capitalize;
}

.banner-hero .block-banner .form-find .btn-find {
  min-width: 122px;
  padding-left: 34px;
  padding-right: 15px;
  /* background-image: url(/_next/static/media/search-icon.ad1e8376.svg); */
  background-repeat: no-repeat;
  background-position: left 19px top 18px;
}

.sidebar-shadow.none-shadow {
  padding: 0 20px 0 0;
  border: 0;
  border-radius: 0;
}

.sidebar-filters {
  padding-top: 5px;
}

.head-border {
  border-bottom: 1px solid #b4c0e0;
  padding-bottom: 10px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.link-reset {
  float: right;
  font-size: 25px;
  line-height: 22px;
  color: #66789c;
  font-weight: 500;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.list-checkbox {
  display: inline-block;
  width: 100%;
  padding: 15px 0 10px;
  border-bottom: 1px solid #e0e6f7;
}

.list-checkbox li {
  position: relative;
  padding: 0 30px 6px 0;
  display: inline-block;
  width: 100%;
}

.cb-container {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
} */
.text-small {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #4f5e64;
  padding-left: 15px;
  text-transform: capitalize
}

input:checked~.checkmark {
  border: 2px solid #3c65f5;
}

/* .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: 1px solid #e0e6f7;
  border-radius: 7px;
} */
.list-checkbox li .number-item {
  position: absolute;
  top: 35%;
  right: 0;
  transform: translateY(-50%);
  padding: 4px 5px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 7px;
  background: #e0e6f7;
  color: #3c65f5;
}

.box-filters-job {
  border-bottom: 1px solid #e0e6f7;
  margin-bottom: 30px;
  padding: 0 0 5px;
}

.paginations {
  margin: 20px 0 50px;
}

.paginations .pager li {
  display: inline-block;
  vertical-align: middle;
}

.paginations .pager li a {
  display: block;
  padding: 10px 8px;
  font-weight: 600;
  color: #a0abb8;
  line-height: 26px;
  text-decoration: none;
  position: relative;
  font-size: 18px;
  font-weight: 700;
}

.box-company-profile {
  max-width: 90%;
  display: table;
  table-layout: fixed;
  margin: auto;
  width: 100%;
  padding: 20px 0 0;
  position: relative;
}

.image-compay {
  /* position: absolute;
  top: -80px;
  left: 0; */
  width:10%
}

.mt-10 {
  margin-top: 10px !important;
}

.hero.banner-image-single {
  padding: 20px 0;
}

.btn-editor {
  background-color: #e6e6e6;
  position: absolute;
  bottom: 21px;
  right: 90px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-bottom--0 {
  border-bottom: 1px solid #dee2e6!important;
}
.nav-tavs-profile {
  padding-right: 30px;
}
.nav-tavs-profile ul li a {
  display: block;
  text-align: left;
  font-size: 17px;
  padding-top: 7px !important;
  padding-bottom: 7px;
  background-position: 17px;
  padding-left: 15px;
  box-shadow: 0 2px 4px -5px #0a2a69;
  color:white !important;
  background-color: #2bb792;
  border: 1px solid #2bb792 !important;
}
.link-red {
  color: #e45959 !important;
 
  line-height: 12px;
  font-weight: 500;
}
.image-profile {
  display: inline-block;
  max-width: 85px;
  vertical-align: middle;
  margin-right: 25px;
}
.box-info-profie .btn-apply {
  background-color: #3c65f5;
  color: #fff !important;
  padding: 12px 20px;
  border-radius: 4px;
}
.mb-40 {
  margin-bottom: 40px!important;
}
.mt-35 {
  margin-top: 35px!important;
}
.form-contact .form-control {
  width: 100%;
  border: 2px solid #e0e6f6;
  border-radius: 4px;
  font-size: 20px;
  line-height: 22px;
  padding:12px
}
.color-orange {
  color: #f58a3c!important;
}
.box-skills {
  border: 2px solid #e0e6f6;
  padding: 22px 27px;
  margin-top: 33px;
  border-radius: 4px;
}
.btn-apply-big {
  background-color:#24496a ;
  color: #fff;
  padding: 11px 35px;
  border-radius: 4px;
  border:none
}
.footer-bottom {
  border-top: 2px solid #1c44b3;
  padding: 30px 0;
  color: #4f5e64;
  font-size: 14px;
}
.font-xs {
  font-weight: 600!important;
  font-size: 16px!important;
  line-height: 18px!important;
  color: #0a2a69;
}
.mr-30 {
  margin-right: 30px!important;
}
.ml-30 {
  margin-left: 30px!important;
}
.whats-app--0{
  display: flex;
    position: fixed;
    bottom: 40%;
    right: 170px;
    z-index: 999;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(180deg,#7751e0 0,#24496a)!important;
    color: white;
    font-size: 25px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 40%);
}
.ui-bg{
  background-image: linear-gradient(90deg,#a68ff8,#24496a);
  height: 11rem;
}
.ui-header-content {
  position: relative;
  display: flex;
  color: #fff;
  align-items: center;
}
.ui-header-content figure {
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(121,105,167,.2);
}
.ui-main {
  box-shadow: 0 3px 14px 0 rgb(212 209 209 / 50%);
  padding: 2rem;
  background: #fff;
  padding: 3rem;
  border-radius: 0.4rem;
}
.ui-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
}
.ui-list li:not(:last-child) {
  margin-right: 3rem;
}
.ui-list li {
  width: 22rem;
  height: 19.5rem;
  overflow: hidden;
  box-shadow: 0 2px 6px 0 hsl(0deg 0% 82% / 50%);
  padding: 2rem;
}
.ui-list li a {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.ui-list li a figure {
  height: 9rem;
  margin-bottom: 2rem;
  display: flex;
}
.ui-list li a h3 {
  font-size: 1rem;
  font-weight: 600;
}
.ui-list li a figure img {
  align-self: flex-end;
  margin: 0 auto;
}
.cs-banner-2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 181px;
  max-height: 181px;
  font-family: poppins-regular-webfont;
  font-style: normal;
  background: #f4f4f5;
  border-radius: 20px;
  margin: 29px 0 20px;
}
.cs-banner-2 .banner-left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 22px;
  padding-right: 100px;
  max-width: 700px;
}
.cs-banner-2 .banner-left .content .banner-heading {
  font-weight: 600;
  font-size: 2.286em;
  color: #1d1934;
  line-height: 38px;
  margin-bottom: 5px;
}
.banner-right .banner-img {
  margin-top:30px;
}
.key-feature-content-inner {
  text-align: center;
}
.key-feature .key-feature-heading {
  margin: 0 22px;
  color: #000;
  line-height: 33px;
  font-weight: 600;
  font-size: 24px;
 
}
.details {
  line-height: 30px;
}
.details>label {
  color: #2bb792;
  width: 150px;
  font-weight: 900;
  font-size:20px;
  vertical-align: top;
  margin-right: 9px;
}
/* Media  Query */
@media only screen and (max-width: 600px) {
  .contact-place-90 {
    display: block;
   
}
.mt-15 {
  font-size: 20px !important;
 
}
.topBarRight {
  display: block;
}
.ui-bg {
  background-image: linear-gradient(90deg,#a68ff8,#24496a);
  height: 18rem;
}
.banner-hero.hero-1{
  height: 245px !important;
  padding: unset;
}
.banner-hero .block-banner {
  position: relative;
  z-index: 12;
  padding: 50px 14px;
}
.banner-hero .heading-banner {
  font-size: 30px;
  line-height: 35px;
  color: #05264e;
}
.img-job-1{
  display: none;
}
.img-job-2{
  display: none;
}
.text-32 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 800;
}
.text-52 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 800;
  color: #05264e;
}
.pr-50 {
  padding-right: 0px !important;
}
.box-we-hiring {
  display: block;
  text-align: center;
}
.banner-hero .block-banner .form-find form {
  display: block;
  
}
.banner-hero .block-banner .form-find .box-industry {
  max-width: none;
}
.login-signup-box {
  position: unset;
  width: 100%;
  margin-top: 2px;
}
.ui-list {
  display: block;
  
}
.ui-list li {
  width: 15rem;
}
.btnLoginRegister .btn {
  padding: 6px 7px;
}
.cs-banner-2 {
  display: block;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  max-height: unset;
}
.cs-banner-2 .banner-left .content .banner-heading {
  padding-top: 21px;
}
.cs-banner-2 .banner-left {
  padding-right: 30px;
}
.image-compay{
  display: none;
}
.login-page-bg{
  margin: 50px 0px 100px !important;
  height:auto !important;
  display:none
}
.header-op--0{
  display: block !important;
}
.ml-30 {
  margin-left: 2px!important;
}
.navbar-brand img{
  margin-top:-10px !important;
  width: 97% !important;
}
.navbar-toggler.collapsed{
  margin-top: 10px;
  
}
.css-1bp8stj-MuiPaper-root{
  width: auto !important;
}
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 2.5px 4px 7.5px 6px !important;
}
.amit-0{
  font-weight: 500;
    font-size: 14px ;
    line-height: 22px;
    color: #a0abb8; 
}
.rahul{
  width: 30px;
    float: right;
    height: 30px;
    color: white;
    background-color: rgb(2, 2, 15);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: -5px;
}
.rahul:hover{
  cursor:pointer;
  background-color: rgb(252, 2, 2);
}
.btn-primary {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: black !important;
  --bs-btn-border-color: black !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #312929!important;
  
}
.vishawjit{
  padding-top:13px
}
.jkdf:hover{
  cursor:pointer;
color: rgb(252, 2, 2) !important;
}
.jjff:hover{
  color: rgb(252, 2, 2) !important;
}
.modal-footer{
  justify-content: space-between !important;
}
/* .modal-dialog{
  width: 90% !important
} */
/* .btn-close{
  display: none !important;
} */
/* .btn-secondary{
  background-color: #000 !important;
} */
.linkedin-1{
  padding: 5px;
  border: 2px solid #0077b5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: #0077b5;
  width: 35px;
}
.linkedin-2{
  padding: 5px;
  border: 2px solid #4267B2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: #4267B2;
  width: 35px;
}
.linkedin-3{
  padding: 5px;
  border: 2px solid #1DA1F2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: #1DA1F2;
  width: 35px;
}
.linkedin-4{
  padding: 5px;
  /* border: 2px solid #405DE6; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.nskhdce{
  display: flex;
  justify-content: space-between;
}
.v-09-0{
  float: right;
  margin-top: -39px;
}
.banner-right{
  display: flex;
  justify-content: center;
  gap:30px;
  align-items: center;
}
.skil-09{
 border:none;
    outline-width: 0px;
}
.create-090{
padding:23px;
}
.skills-lbl h3{
  font-size: 20px;
  font-weight: 600;
}
.q1ro_q-full-name .skil-09-0{
  color: black;
  border: none;
  outline-width: 0px;
  font-size: 25px;
  font-weight: 600;
}
.hdhe-00 .skil-09-0{
  border: none;
  outline-width: 0px;
  padding: 5px;
  color: black;
  width:100%;
  border-bottom:1px solid black
}
.educt-09{
  display:flex;
  gap:11px;
}
.lk-nh-i{
  font-size: 20px;
  font-weight: 600;
}
.save-date-08{
  display: flex;
  justify-content: space-between;
}
.camera-rdit{
  position:absolute;
  left:17%;
  margin-top:-24px;
  font-size: 25px;

}
.camer-oi{
position:absolute;
left:16%;
margin-top:-10px
}
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  font-size: 40px;
  -webkit-tap-highlight-color: transparent;
}

/* qwjd9wefh9pwehf9wehfw9ahgfqwe9gb */
.esw{
  font-weight: 900;
    font-size: 20px;
    line-height: 48px;
}
.co-logo{
  padding: 0px 46px;
}
.ada-0{
  padding-top:30px ;
}
/* new css */
.foot_heo{
  background-color: rgb(43, 183, 146);
  padding: 25px;
}
.ab-tes h3{
  color: white;
  font-size: 26px;
  font-weight: 700;
}
.ab-tes p{
  color: white;
 
  font-weight: 700;
}
.contact_us ul{
  list-style: none;
  padding: 0px;
}
.contact_us ul li{
color: white;
font-size: 18px;
font-weight: 500;
line-height: 32px;
}
.address_hero-1{
  color: white;
  font-size: 17px;
  font-weight: 600;
}
.cont-0{
  color: white;
  font-size: 27px;
  font-weight: 600;
}
.add_eh h3{
  color: white;
  font-size: 27px;
  font-weight: 600;
}
.H_u_O{
  position: relative;
  display: flex;
  justify-content: center;
  bottom: -58px;
}
.los{
  border: 2px solid;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 700;
  background-color: white;
  text-align: center;
  border-color: darkblue;
  line-height: 26px;
}
.add_eh{
  padding-top: 60px;
}
.footers-Hewo{
  padding-top: 60px;
}
.contact_us ul li span{
  padding-right: 10px;
}
.socila-fo ul{
  display: flex;
    list-style: none;
}
.socila-fo ul .linkedin {
  margin: 0px 11px;
  border: 1px solid #0a66c2;
  padding: 5px;
  border-radius: 3px;
  background-color: #0a66c2;
  cursor: pointer;
}
.facebook{
  margin: 0px 11px;
  border: 1px solid #1877f2;
  padding: 5px;
  border-radius: 3px;
  background-color: #1877f2;
  cursor: pointer;
}
.insta{
  margin: 0px 11px;
  border: 1px solid #ea4055;
  padding: 5px;
  border-radius: 3px;
  background-color: #ea4055;
  cursor: pointer;
}
.pinterest{
  margin: 0px 11px;
  border: 1px solid #E60023;
  padding: 5px;
  border-radius: 3px;
  background-color: #E60023;
  cursor: pointer;
}
.sbtn h3{
  background-color: #2bb792;
  font-size: 25px;
  border: 1px solid #2bb792;
  color: white;
  padding: 2px 10px;
  border-radius: 20px;
}
.ad-lo{
  height: auto;
  margin: 20px 150px;
  border-radius: 10px;
  background-color: #2bb792;
}
.p-hero{
  cursor: pointer;
  color: rgb(13, 110, 253);
  border: 1px solid;
  padding: 5px 20px;
  background-color: #2bb792;
  border-radius: 10px;
  color: white;
}
.login-page-bg-00{
  display:flex;
  justify-content: center;
  height: auto;
  margin: 20px 260px;
  border-radius: 10px;
  background-color: #2bb792;
}
.register-0{
  font-family: "Open Sans", sans-serif;
    padding: 20px;
    background-color: aliceblue;
}
.photo-camera-0{
  left: 50px;
    top: 63px;
    color: red;
    position: absolute;
}
.content-single{
  border-radius: 8px;
  background-color: unset;
  border: 1px solid rgba(0, 0, 0, 0.28);
  position: relative;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    padding: 15px;
   
}
.mod_hero .modal-dialog {
    background-color: white !important;
}
.ant-btn-primary {
  background-color: #2bb792 !important;
}
.ant-btn {
  font-size: 18px !important;
  height: 40px !important;
}
.btn:hover {
  background-color: #2bb792 !important;
}
.hero_link_j a{
  font-size: 18px;
  font-weight: 600;

  color: white !important;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.hero_link_j{
  padding-top:21px;
  font-size: 18px;
  font-weight: 600;
  background-color: rgb(43, 183, 146);
color:white;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 10px;

    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;

}
.hero_link_j:hover{
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
/* New Csss media query */

@media only screen and (max-width: 600px) {
  .esw {
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
}
   .img_he{
    text-align: center;
   }
   .iconWrap .btn.btn-secondary {
    font-size: 14px !important;
   
   }
   .iconWrap.btnLoginRegister.mr-30{
    margin-right: -12px!important;
   }
   .btnLoginRegister {
    display: flex;
    align-items: center;
    justify-content: center;
}
   .ada-0 {
    padding-top: unset;
    padding-bottom: 10px;
}
.box-we-hiring{
  margin-right: unset !important;
}
.box-we-hiring{
  margin-left: unset !important;

}
.btn {
  padding: unset !important;
}
.text-0 img{
  height: 251px !important;
}
.section-title.mb-10 {
  margin-bottom: 2px !important;
  font-size: 30px;
  font-weight: 800;
  margin-top: 13px;
}
.mb-30 {
  margin-top: unset !important;
}
.H_u_O {
  position: relative;
  display: flex;
  justify-content: center;
  bottom: unset;
  margin-bottom: 18px;
}
.socila-fo ul {
  padding: 0px;
}
.add_eh {
  padding-top: unset;
}
.login-img{
  display: none;
}
.ad-lo {
  margin: unset;
}
.register {
  padding: 7px;
}
.add-login {
  display: flex;
  justify-content: center;
  padding: 4px;
  margin-top: 20px;
}
.form-check {
  padding-left: 12px !important;
  
}
.hro+p{
  margin-top:11px
}
.login-page-bg-00{
  margin: 19px 0px;
}
.register-0 {
  padding: 5px !important;
}
}

.table>:not(caption)>*>* {
 
  background-color: white !important;
}
.card-title {
  font-size: 18px !important;
}
.ant-modal .ant-modal-body {
  font-size: 19px !important;

}
.ant-modal-body h6{
  font-size: 19px !important;
}
.dvd:hover{
  transform: scale(1.02);
  transition: all;
  font-size: 20px;
  color:rgb(43, 183, 146)
}
.owl-carousel {
  z-index: 999 !important;
}
.paginationBttns {
  width: 97%;
  height: 30px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.paginationBttns a {
  padding: 6px 10px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid rgb(43 183 146);
  color: rgb(43 183 146);
  cursor: pointer;
  text-decoration: none;
}

.paginationBttns a:hover {
  color: white;
  background-color: rgb(43 183 146);
}

.paginationActive a {
  color: white;
  background-color: rgb(43 183 146);
}