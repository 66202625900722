.twonhub{


table {
  font-family: arial, sans-serif;
  width: 100%;
}

td, th {
  text-align: left;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
th{
  color: black;
}
a{
  text-decoration: none;
  color: rgb(58, 53, 53);
}
li{
  font-size: 20px;
  margin: 10px 20px;
  text-decoration: none;
}
.btn.btn-outline-info:hover{
background-color: rgb(5, 38, 78);
color: white !important;
}
.btn :hover{
  background-color:  rgb(45, 90, 145);
}


@media only screen and (max-width: 600px) {
 table{
    background-color: lightblue;
    font-size: 7px !important;
  }
  .btn.btn-outline-primary{
    padding: 0px 23px 0px 0px !important;
  }
  .btn.btn-danger{
    padding: 0px 40px 0px 0px !important;
  }
  .btn.btn-success{
    padding: 0px 24px 0px 0px !important;
  }
  .col-md-3.fixed{
    height: 100% !important;
    position: relative !important;
  }
  .text-white {
    margin: 1px !important;
  }
  /* .hoverZoomLink{
   height: 30px !important;
   width: 110% !important;
  } */
  img, svg {
    vertical-align: middle;
    /* width: 20px !important; */
}
.col-md-3.add {
  height: 100% !important;

}
.container.img{
  width: 30% !important;
  display: contents !important;
}
.btn.btn-outline-info{
width: 60px !important;

}
.adim{
  margin-bottom: -69px;
    margin-left: 163px;
}
}
.col-md-2 {
    flex: 0 0 auto;
    width: 19.666667%;
}
.MDBCardTitle{
  font-size: px;
}
}